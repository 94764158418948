import React from 'react';
import s from './footer.module.css';
import {BOTTOM_TEXT} from './footer.constants';
import fondImage from "shared/assets/Fond-M.png";
import { useTranslate } from 'shared/translation/utils';

export const Footer: React.FC = () => {
  const t = useTranslate();

  return (
    <footer className={s.wrapper}>
        <a href="https://fasie.ru/" target="_blank" rel="noreferrer">
          <div className={s.fondWrapper}>
            <div className={s.fondImageWrapper}>
              <img src={fondImage} alt="" />
            </div>
            <p>{t("investorInfo")}</p>
          </div>
        </a>
      <span className={s.bottomText}>{BOTTOM_TEXT}</span>
    </footer>
  );
};
