import React, {useEffect, useState} from 'react';
import {HeaderMobileView} from './header-mobile.view';

type Props = {
  children: React.ReactNode;
  aboutUsText: string;
}

export const HeaderMobile: React.FC<Props> = ({children, aboutUsText}) => {
  const [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    }
  }, [active]);

  const clickBurgerFn = () => {
    setActive((active: boolean) => !active);
  };

  const onClickLink = () => {
    setActive(false);
  }

  return <HeaderMobileView active={active} clickBurgerFn={clickBurgerFn} onClickLink={onClickLink} aboutUsText={aboutUsText}>{children}</HeaderMobileView>;
};

