import React from "react";
import s from "./about-us-container.module.css";
import fondImage from "shared/assets/Fond-M.png";

type Props = {
  companyDescription: string;
  bossInfo: string;
  contactsInfo: string;
  investorInfo: string;
};

/**
 * @return {React.FC<Props>}
 **/
export const AboutUsContainerView: React.FC<Props> = ({
  companyDescription,
  bossInfo,
  contactsInfo,
  investorInfo,
}: Props) => {
  return (
    <div className={s.wrapper}>
      <div className={s.infoBlock}>
        <p>{companyDescription}</p>
        <p>{bossInfo}</p>
        <p>
          {contactsInfo}{" "}
          <a href="mailto:gdaeonstudion@gmail.com">gdaeonstudion@gmail.com</a>
        </p>
        <a href="https://fasie.ru/" target="_blank" rel="noreferrer">
          <p>{investorInfo}</p>
        </a>
      </div>
      <a href="https://fasie.ru/" target="_blank" rel="noreferrer">
        <img
          src={fondImage}
          alt="Фонд содействия инновациям"
          className={s.fondImage}
        />
      </a>
    </div>
  );
};
